import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import Chart from './chart-1.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`PSI is a not-for-profit school governed by the Board of Governors elected from within the parent community. All School income is generated by tuition fees and is redistributed to teaching and learning activities and campus operation support. Separately, the School collects a Strategic Development Charge (formerly Capital Levy) that is being accumulated in Strategic Development Funds for the purposes of capital investments and strategic long-term initiatives.`}</p>
    <h4 {...{
      "id": "the-graphs-below-illustrate-income-sources-and-expenses-categories-for-the-br2019---2020-school-year",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#the-graphs-below-illustrate-income-sources-and-expenses-categories-for-the-br2019---2020-school-year",
        "aria-label": "the graphs below illustrate income sources and expenses categories for the br2019   2020 school year permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The graphs below illustrate Income sources and Expenses categories for the `}<br />{`2019 - 2020 school year.`}</h4>
    <Chart mdxType="Chart" />
    <p>{`The graphs above are generally typical for not for profit international schools.`}</p>
    <p>{`Staff and their expertise are seen as the major expense and greatest asset of the school.`}</p>
    <p>{`The table below displays the school's financial performance over five years, including our budget for the new SY 2020 - 2021.`}</p>
    <h4 {...{
      "id": "financial-data-for-the-period--from-sy-2016---17-to-sy-2020---21-usd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#financial-data-for-the-period--from-sy-2016---17-to-sy-2020---21-usd",
        "aria-label": "financial data for the period  from sy 2016   17 to sy 2020   21 usd permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Financial data for the period  from SY 2016 - 17 to SY 2020 - 21 USD`}</h4>
    <Table fullWidth innerWidth={1000} mdxType="Table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}>{`School Year`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`2016 / 2017`}<br />{`Actual`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`2017 / 2018`}<br />{`Actual`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`2018 / 2019`}<br />{`Actual`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`2019 / 2020`}<br />{`Acual`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`2020 / 2021`}<br />{`Forecast 1`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Enrollment (paid students)`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`397`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`425`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`429`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`483`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`472`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Operational Revenue`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`9,6`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`10,4`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`10,6`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`11,6`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`11,3`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Operational Expenses`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`(7,8)`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`(9,1)`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`(10,1)`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`(10.8)`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`(11,2)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Operational Income`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`1,7`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`1,3`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`0,5`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`0,8`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`0,1`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Accumulated cash at the end of School year*`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`3,0`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`3,2`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`3,8`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`5,1`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`4,0`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`*including Reserve Fund`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`0.5`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`2,2`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`2,5`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`3,5`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`3,6`}</td>
          </tr>
        </tbody>
      </table>
    </Table>
    <p>{`PSI continues to increase the reserve fund to strengthen its financial and operational stability. The reserve fund reached 3.5 million USD in SY 2019 - 2020, reflecting the School's strong position in regards to expected economic shortfalls due to COVID-19. The Strategic Development Fund aims to ensure the School has funds for its development and strategic initiatives to improve the learning process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      